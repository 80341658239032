export const NF_PLUS_MAIN_URL = 'https://nordiskfilmplus.com';

export const DEFAULT_PHONE_COUNTRY_CODES = [
  { code: 'dk', value: '+45', size: 8 },
  { code: 'fo', value: '+298', size: 9 },
  { code: 'gl', value: '+299', size: 9 },
  { code: 'no', value: '+47', size: 8 },
  { code: 'se', value: '+46', size: 9 },
  // { code: 'fi', value: '+358' },
];

export const DEFAULT_LOCALES = [
  { id: 'da', name: 'Dansk', lngToFile: 'da_DK' },
  { id: 'en', name: 'English', lngToFile: 'en_GB' },
  { id: 'no', name: 'Norsk', lngToFile: 'nb_NO' },
  // { id: 'fo', name: 'Faroe Islands', lngToFile: 'fo_FO' },
  // { id: 'sv', name: 'Svenska' },
];
