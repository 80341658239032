import { QueryClient, QueryClientConfig } from '@tanstack/react-query';

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      retry: false,
      // useErrorBoundary: false,
      // suspense: false,
    },
    mutations: {
      // useErrorBoundary: false,
      // suspense: false,
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);
