import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type NFInputProps = {
  className?: string;
  id?: string;
  type?: string;

  name?: string;
  label?: string | null;
  placeholder?: string | null;
  dataCy?: string;
  readonly?: boolean;
  disabled?: boolean;
  value?: string | number;
  error?: string | null;
  onClick?;
  onChange?;
  onFocus?;
  style?;

  renderErrors?;
  checked?;
  children?;
  errors?;
  errorLabel?;
  register?;
  validate?;
  required?;
  minLength?;
  maxLength?;
  pattern?;
  defaultValue?;
  autoComplete?;
};

const Input = ({
  children,
  className,
  errors,
  renderErrors,
  name,
  label,
  errorLabel,
  checked,
  type = 'text',
  readonly,
  register,
  // style,
  validate,
  required,
  minLength,
  maxLength,
  pattern,
  ...props
}: NFInputProps) => {
  const { t } = useTranslation();
  const classes = classNames(
    !errors?.[`${name}`] && `border`,
    errors?.[`${name}`] && `border-red-700 border`,
    !className && `w-full p-4 rounded text-black text-opacity-60 font-normal text-base mt-1`,
    !!className && className
  );

  const inputId = props?.id ?? name;
  const computedErrorLabel = label ?? errorLabel ?? 'Field';

  return (
    <div className="w-full">
      <label className="font-bold" htmlFor={`input-${inputId}`}>
        {label}
        <input
          id={`input-${inputId}`}
          className={classes}
          type={type}
          readOnly={readonly}
          checked={checked}
          aria-describedby={`validation-${inputId}`}
          data-testid={`input-${inputId}`}
          {...register(name ?? 'no-name', { validate, required, minLength, maxLength, pattern })}
          {...props}
          style={
            props.disabled
              ? {
                  backgroundColor: '#b7b7b7',
                  cursor: 'default',
                }
              : {}
          }
        />
        <div className="h-8 p-1 font-normal text-center lg:text-left">
          {children}
          {!!renderErrors && (
            <span className="font-bold text-xs text-red-700" id={`errors-${inputId}`}>
              {renderErrors}
            </span>
          )}
          {errors?.[`${name}`] && (
            <span className="font-bold text-xs text-red-700" role="alert" id={`errors-${inputId}`}>
              ! {errors?.[`${name}`]?.message}
              {errors?.[`${name}`]?.type === 'maxLength' && `${computedErrorLabel} ${t('web_forms_maxLength')}`}
              {errors?.[`${name}`]?.type === 'minLength' && `${computedErrorLabel} ${t('web_forms_minLength')}`}
            </span>
          )}
        </div>
      </label>
    </div>
  );
};

export default Input;
