import Input from '@/components/forms/Input';
import Checkmark from '@/assets/checkmark.svg?react';

const EmailInput = ({ isEmailVerified = false, ...props }) => {
  return (
    <div className="w-full">
      <div className="relative mt-1 rounded-md shadow-sm">
        <Input
          {...props}
          style={
            props.disabled
              ? {
                  backgroundColor: '#b7b7b7',
                  cursor: 'default',
                }
              : {}
          }
        />
        <div className={`absolute inset-y-0 right-0 flex items-center px-4`}>{isEmailVerified && <Checkmark />}</div>
      </div>
    </div>
  );
};

export default EmailInput;
