/* eslint-disable react/no-danger*/
import { useTranslation } from 'react-i18next';

import Button from '@/components/forms/Button';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="lg:gap-4 lg:flex">
        <div className="flex flex-col items-center justify-center">
          <h1 className="pb-3 font-bold text-blue-500 text-9xl">{t('web_notFound_title')}</h1>
          <p
            className="mb-4 text-2xl font-bold text-center md:text-3xl terms"
            dangerouslySetInnerHTML={{
              __html: t('web_notFound_description'),
            }}
          ></p>
          <p className="mb-8 text-center md:text-lg">{t('web_notFound_smallDescription')}</p>
          <a href="https://nordiskfilmplus.com">
            <Button type="button" className={'w-[12rem]'}>
              {t('web_notFound_goHome')}
            </Button>
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
