export type StepNavigationStep = {
  label: string;
};

type Props = {
  labelArray: StepNavigationStep[];
  currentStep: number;
};

export default function StepNavigation({ labelArray, currentStep }: Props) {
  return (
    <div className="stepWrapper flex mb-5">
      {labelArray.map((item, index) => (
        <div
          aria-label={item.label}
          title={item.label}
          key={index}
          className={'text-center stepBlock' + (currentStep === index + 1 ? ' selected' : '')}
        >
          <div className="circleWrapper relative">
            <div className="w-7 h-7 leading-5 bg-blue-500 text-white rounded-full border-2 border-transparent bg-clip-padding-box relative ml-[-1] z-10 circle">
              {index + 1}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

// export default function StepNavigation(props) {
//   return (
//     <div className="stepWrapper flex mb-5">
//       {props.labelArray.map((item, index) => (
//         <Step
//           key={index}
//           index={index}
//           label={item}
//           updateStep={props.updateStep}
//           selected={props.currentStep === index + 1}
//         />
//       ))}
//     </div>
//   );
// }
