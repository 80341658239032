import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import AppProviders from './context/app-providers';
import './i18n';
import './index.css';
import App from './App';

TagManager.initialize({
  gtmId: `${import.meta.env.VITE_GTM_ID}`,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);
