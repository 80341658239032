import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import NotFound from './pages/NotFound';
import Validate from './pages/Validate';
import LanguageSelector from './components/LanguageSelector';

import Loading from './components/Loading';
import Pelle from './components/consent/Consent';
import NFEgmontLogo from './assets/logo-nordisk-film-egmont-white.svg';

import NFLogo from './assets/logo-nfplus.svg';

import { NF_PLUS_MAIN_URL } from './appConfig';

const Signup = lazy(() => import('./pages/Signup'));
const SignupSuccess = lazy(() => import('./pages/SignupSuccess'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('./pages/ForgotPasswordSuccess'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const VerifyNewEmail = lazy(() => import('./pages/VerifyNewEmail'));
const VerifyMarketing = lazy(() => import('./pages/VerifyMarketing'));

function App() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen p-8 text-white bg-blue-900">
      <header className="text-center lg:text-left">
        <a target="_blank" rel="noreferrer" href={NF_PLUS_MAIN_URL}>
          <img
            className="w-[100px] h-[100px] mx-auto lg:w-[70px] lg:h-[70px] lg:ml-0 my-8 lg:my-0"
            src={NFLogo}
            alt="Nordisk Film+ Logo"
          />
        </a>
      </header>

      <main className="flex-1 w-full max-w-sm m-auto">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<NotFound />} />
            <Route path="/:cc/:lang/signup" element={<Signup />} />
            <Route path="/:cc/:lang/signup-success" element={<SignupSuccess />} />
            <Route path="/:cc/:lang/confirm/:token" element={<Validate />} />
            <Route path="/:cc/:lang/forgot-password" element={<ForgotPassword />} />
            <Route path="/:cc/:lang/forgot-password-success" element={<ForgotPasswordSuccess />} />
            <Route path="/:cc/:lang/reset-password/:token" element={<ResetPassword />} />
            <Route path="/:cc/:lang/verify-new-email/:token" element={<VerifyNewEmail />} />
            <Route path="/:cc/:lang/verify-marketing/:token" element={<VerifyMarketing />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>

      <footer className="items-center justify-between hidden md:flex font-black sm:text-sm">
        <div>
          <img src={NFEgmontLogo} />
        </div>
        <div>
          <ul className="flex items-center space-x-12">
            <li>
              <a rel="noreferrer" target="_blank" href="https://help.nordiskfilmplus.com/da/articles/38">
                {t('web_footer_termsAndConditions')}
              </a>
            </li>
            <li>
              <a rel="noreferrer" target="_blank" href="https://help.nordiskfilmplus.com/da/articles/36">
                {t('web_footer_privacyPolicy')}
              </a>
            </li>
            <li>
              <a rel="noreferrer" target="_blank" href="https://help.nordiskfilmplus.com">
                {t('web_footer_help')}
              </a>
            </li>
            <li>
              <a rel="noreferrer" target="_blank" href="https://help.nordiskfilmplus.com/da/categories/8">
                {t('web_footer_contactUs')}
              </a>
            </li>
            <li>
              <LanguageSelector />
            </li>
          </ul>
        </div>
      </footer>
      <Pelle />
    </div>
  );
}

export default App;
