import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import i18next from '../i18n';

import ChevronDownIcon from '../assets/icon-chevron-down.svg';
import GlobeIcon from '../assets/icon-globe.svg';
import { DEFAULT_LOCALES } from '../appConfig';

const LanguageSelector = () => {
  const locales = DEFAULT_LOCALES;
  const [selectedLocale, setSelectedLocale] = useState(locales.find((f) => f.id === i18next.language));

  const handleChange = (locale) => {
    setSelectedLocale(locale);
    i18next.changeLanguage(locale.id);
  };

  return (
    <Listbox value={selectedLocale} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="relative w-48 mt-1">
            <ListboxButton className="footer relative w-full py-2 pl-3 pr-10 text-left rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
              <div className="flex items-center">
                <img src={GlobeIcon} className="w-[16px]" />
                <span className="block ml-3 truncate">{selectedLocale?.name ?? `-`}</span>
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <img src={ChevronDownIcon} className={`${!open || 'rotate-180'}`} />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              // className="absolute w-full mb-1 rounded-md shadow-lg bottom-full"
            >
              <ListboxOptions className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base rounded-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {locales
                  .filter(({ id }) => id !== selectedLocale?.id)
                  .map((locale) => {
                    return (
                      <ListboxOption
                        key={locale.id}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-blue-600 rounded' : '',
                            'cursor-default select-none relative py-2 pl-3 pr-9 '
                          )
                        }
                        value={locale}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className={classNames(selected ? 'hidden' : 'flex items-center')}>
                              <span
                                className={classNames(
                                  selected ? 'text-red-500' : 'text-orange-500',
                                  // locale.online ? 'bg-green-400' : 'border-gray-200',
                                  'border flex-shrink-0 inline-block h-2 w-2 rounded-full'
                                )}
                                aria-hidden="true"
                              />
                              <span className={classNames('ml-3 block truncate font-semibold')}>
                                {locale.name}
                                {/* <span className="sr-only"> is {locale.online ? 'online' : 'offline'}</span> */}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                CHECK
                              </span>
                            ) : null}
                          </>
                        )}
                      </ListboxOption>
                    );
                  })}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default LanguageSelector;
