import { useTranslation } from 'react-i18next';

import Input from '@/components/forms/Input';
import { DEFAULT_PHONE_COUNTRY_CODES } from '../../appConfig';

const PHONE_REGEXP = /^(0|[1-9]\d*)(\.\d+)?$/; // eslint-disable-line security/detect-unsafe-regex

const PhoneInput = ({ label, cc, defaultValue, type, name, register, errors, disabled = false }) => {
  const { t } = useTranslation();

  const computedErrorLabel = `${label} (${t('web_phone_length_validation_message')})`;
  // const computedCountryCodeMin = DEFAULT_PHONE_COUNTRY_CODES.find((f) => f.value === currentCountryCode)?.size ?? 8;
  // const computedCountryCodeMax = DEFAULT_PHONE_COUNTRY_CODES.find((f) => f.value === currentCountryCode)?.size ?? 15;

  return (
    <div className="w-full">
      <label htmlFor="phone-number" className="font-bold">
        {label}
      </label>
      <div className="relative mt-1 rounded-md">
        <div className="absolute h-14 top-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Country code
          </label>
          <select
            id="country"
            name="phone_country_code"
            {...register('phone_country_code')}
            autoComplete="phone_country_code"
            disabled={disabled}
            className="h-full w-18 py-0 apl-3 pr-8 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            defaultValue={DEFAULT_PHONE_COUNTRY_CODES.find((f) => f.code === cc)?.value}
          >
            {DEFAULT_PHONE_COUNTRY_CODES.map((_cc) => (
              <option key={_cc.value}>{_cc.value}</option>
            ))}
          </select>
        </div>

        <Input
          className="font-normal block w-full py-4 pl-[5.0rem] text-gray-700 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder={t('web_forms_phone_number_placeholder')}
          errorLabel={computedErrorLabel}
          disabled={disabled}
          type={type}
          name={name}
          pattern={{
            value: PHONE_REGEXP,
            message: t('web_phone_pattern_message'),
          }}
          // minLength={computedCountryCodeMin}
          // maxLength={computedCountryCodeMax}
          register={register}
          errors={errors}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
