import classNames from 'classnames';

const Button = ({
  children,
  className = '',
  loading = false,
  disabled = false,
  type = 'button',
}: {
  children?: any;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}) => {
  const theme = 'text-white';
  const disabledClass = 'disabled:cursor-not-allowed';
  const loadingClass = 'disabled:cursor-progress';
  const classes = classNames(
    'rounded-full font-black px-4 py-2 my-4 min-w-[12rem]',
    className,
    theme,
    disabled && disabledClass,
    loading && loadingClass
  );
  return (
    <button type={type} disabled={disabled} className={classes}>
      {children}
    </button>
  );
};

export default Button;
