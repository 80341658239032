import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '../api/reactQueryConfig';

type AppProvidersProps = {
  children: React.ReactNode;
};

function AppProviders({ children }: AppProvidersProps) {
  const [qC] = React.useState(() => queryClient);

  return (
    <BrowserRouter>
      <QueryClientProvider client={qC}>{children}</QueryClientProvider>
    </BrowserRouter>
  );
}

export default AppProviders;
