import Input from '@/components/forms/Input';
import Checkmark from '@/assets/checkmark.svg?react';

const TermsCheckbox = ({
  currentValue = false,
  label = '',
  name = '',
  id = '',
  value = '',
  errors,
  register,
  // defaultChecked,
  required,
}) => {
  return (
    <fieldset className="w-full mt-2">
      <legend className="sr-only">{name}</legend>
      <div className="relative flex items-start">
        <div className="flex items-center h-12">
          <Input
            type="checkbox"
            placeholder="12 34 12 34"
            className="w-5 h-5  text-blue-500 border-gray-300 rounded focus:ring-blue-500"
            name={name}
            required={required}
            register={register}
            value={value}
            // defaultChecked={defaultChecked}
          >
            {!!currentValue && (
              <svg className="absolute inset-y-[1.5px] left-1 w-[12px]">
                <Checkmark />
              </svg>
            )}
          </Input>
        </div>

        <div className="ml-4 text-xs">
          <label id={`${name}-description`} htmlFor={`checkbox-${id}`}>
            {/* eslint-disable-next-line*/}
            <div dangerouslySetInnerHTML={{ __html: label }} className="terms" />
            {errors?.[`${name}`] && (
              <div className="mt-1 text-xs text-red-700" id={`errors-${id}`}>
                ! {errors?.[`${name}`]?.message}
              </div>
            )}
          </label>
        </div>
      </div>
    </fieldset>
  );
};

export default TermsCheckbox;
