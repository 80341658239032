/* eslint-disable react/no-danger*/
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import TagManager from 'react-gtm-module';
import StepNavigation from '../components/progressBar/StepNavigation';
import Input from '@/components/forms/Input';
import Button from '@/components/forms/Button';
import TermsCheckbox from '@/components/forms/TermsCheckbox';
import PhoneInput from '@/components/forms/PhoneInput';
import PasswordInput from '@/components/forms/PasswordInput';
import EmailInput from '@/components/forms/EmailInput';
import { AxiosErrorComponent } from '@/components/Errors';
import { HttpStatusCode } from 'axios';

/**
 * Three steps to sign up
 * 1. signup = enter email address
 * 2. verify = verify email address by clicking link
 * 3. validate = enter personal information
 *
 * enum CustomerStatus {
 *   pending_verification // = has entered email address
 *   verified // = has verified email address = clicked link in email
 *   validated // = has entered personal information
 *   anonymised // = I have no idea 🤷
 * }
 */

import { useAvailableConsentsFromToken, useVerifyCustomer, useValidateCustomer } from '@/api/generated/customer';
import {
  AvailableConsentItemResponseDto,
  ConsentItemDto,
  GetVerifyCustomerConflictDto,
  GetVerifyCustomerConflictDtoMessage,
  PostValidateCustomerConflictDto,
  PostValidateCustomerConflictDtoMessage,
  UnprocessableEntityDto,
  VerifyCustomerDataDto,
} from '@/api/generated/customer.schemas';
import { ErrorType } from '@/api/axios-instance';
import { signupNavigationSteps } from './signupNavigationSteps';

const Validate = () => {
  const [currentStep] = useState(2);

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: 'onTouched' });
  const [showUnknownErrors, setShowUnknownErrors] = useState(false);

  const { t } = useTranslation();
  const { token, cc } = useParams();
  const [notification, setNotification] = useState('');

  const [serviceConsent, setServiceConsent] = useState<AvailableConsentItemResponseDto | null>(null);
  const [availableConsents, setAvailableConsents] = useState<AvailableConsentItemResponseDto[]>([]);

  const [verifiedCustomer, setVerifiedCustomer] = useState<VerifyCustomerDataDto | undefined>();

  const { data: availableConsentsData, error: availableConsentsError } = useAvailableConsentsFromToken(token ?? '', {
    query: { enabled: !!token && !!verifiedCustomer, queryKey: ['availableConsents', token] },
  });

  const { data: verifyCustomerData, error: verifyCustomerError } = useVerifyCustomer(token ?? '', {
    query: { queryKey: ['verify', token], enabled: !!token },
  });

  useEffect(() => {
    if (availableConsentsData) {
      const allConsents = availableConsentsData?.consents ?? [];
      const foundServiceConsent = allConsents.find((consent) => consent.name.endsWith('_service'));

      if (foundServiceConsent) {
        setServiceConsent(foundServiceConsent);
        const updatedAllConsents = allConsents.filter((consent) => !consent.name.endsWith('_service'));
        setAvailableConsents(updatedAllConsents);
      }
    }
  }, [availableConsentsData]);

  useEffect(() => {
    if (verifiedCustomer) {
      setValue('email', verifiedCustomer?.email);
      setValue('first_name', verifiedCustomer?.first_name);
      setValue('last_name', verifiedCustomer?.last_name);
      // TODO setValue('phone', magic functionality here);
    }
  }, [verifiedCustomer]);

  useEffect(() => {
    if (verifyCustomerData) {
      setVerifiedCustomer(verifyCustomerData.data);
    }
  }, [verifyCustomerData]);

  useEffect(() => {
    if (availableConsents) {
      const marketingKey =
        availableConsents?.find((f) => !!f?.name?.includes('marketing'))?.name ?? `broadcaster_marketing`;
      setValue(marketingKey, !!verifyCustomerData?.data.marketing_consent_state);
    }
  }, [availableConsents]);

  useEffect(() => {
    if (verifyCustomerError && verifyCustomerError.response) {
      // NOT A REAL ERROR
      if (verifyCustomerError.response.status === HttpStatusCode.Conflict) {
        const typedError = verifyCustomerError as ErrorType<GetVerifyCustomerConflictDto>;
        if (typedError.response?.data.message === GetVerifyCustomerConflictDtoMessage.CUSTOMER_ALREADY_VERIFIED) {
          setVerifiedCustomer(typedError.response?.data.data);
          setShowUnknownErrors(false);
        } else if (
          typedError?.response?.data.message === GetVerifyCustomerConflictDtoMessage.CUSTOMER_ALREADY_VALIDATED
        ) {
          if (typedError.response?.data.data.redirect_url) {
            window.location.href = typedError.response?.data.data.redirect_url;
          }

          // continue, in case the redirect_url is not set for some reason
          setVerifiedCustomer(typedError.response?.data.data);
          setNotification(t('web_error_customer_already_validated'));
          setShowUnknownErrors(false);
        }
      } else {
        setShowUnknownErrors(true);
      }
    }
  }, [verifyCustomerError]);

  const mutation = useValidateCustomer({
    mutation: {
      onSuccess: (res) => {
        try {
          TagManager.dataLayer({
            dataLayer: {
              event: 'genericGAEvent',
              eventCategory: 'sign_up',
              eventAction: 'sign_up completed',
              eventLabel: '',
              eventValue: '',
              nonInteraction: false,
            },
          });
        } catch (gtmError) {
          console.log('gtmError :>> ', gtmError);
        }
        reset();
        setShowUnknownErrors(false);
        window.location.href = `${res.data?.redirect_url}?loginToken=${res.data?.login_token}`;
      },
      onError: (error) => {
        // Not a real error
        if (error.response?.status === HttpStatusCode.Conflict) {
          const typedError = error as ErrorType<PostValidateCustomerConflictDto>;
          if (typedError.response?.data.message === PostValidateCustomerConflictDtoMessage.CUSTOMER_ALREADY_VALIDATED) {
            setNotification(t('web_error_customer_already_validated'));
            setShowUnknownErrors(true);
          } else {
            setShowUnknownErrors(true);
          }
          return;
        }
        if (error.response?.status === HttpStatusCode.UnprocessableEntity) {
          const typedError = error as ErrorType<UnprocessableEntityDto>;
          const errorValidationFields = typedError.response?.data.errors?.map((i) => i?.field) ?? [];
          if (errorValidationFields.includes('phone')) {
            setShowUnknownErrors(false);
            setError('phone_no_country_code', {
              type: 'phone_no_country_code',
              message: t(`web_phone_invalid_validation_message`),
            });
          } else {
            setShowUnknownErrors(true);
          }
          return;
          // if (errorValidationFields.includes('consents')) {
          //   setShowUnknownErrors(true);
          // }
        }
        setShowUnknownErrors(true);
        return;
      },
    },
  });

  useEffect(() => {
    const subscription = watch(({ password }, { name }) => {
      if (name === 'password') {
        // Trigger validation when typing so it runs before you TAB/switch focus
        if (String(password).length >= 10) trigger('password');
      }
      if (name === 'phone_country_code') {
        // Trigger validation when changing country code in select-box
        trigger('phone_no_country_code');
      }
      if (name?.includes('_terms') || name?.includes('_marketing')) {
        // Manually trigger validation to force re-render, this enables Create user button
        // and makes you not having to focus something else in the page to trigger the validation.
        trigger('phone_no_country_code');
        if (String(password).length >= 10) trigger('password');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const onSubmit = handleSubmit((data) => {
    const mappedFormDataToAvailableConsents: ConsentItemDto[] = [];
    let addedConsent = true;
    // Get all availableConsents from the form that has been submitted
    availableConsents.forEach((c) => {
      // Add the consent to the mapped array
      mappedFormDataToAvailableConsents.push({
        state: data?.[c.name] ? 'accepted' : 'declined',
        name: c.name,
        version: c.version,
      });
      // Check if user has submitted the form with accepted terms, which means we are gonna add the _service consents
      const termsConsent = availableConsents.find((consent) => consent.name.endsWith('_terms'));
      if (serviceConsent && termsConsent?.name && addedConsent) {
        mappedFormDataToAvailableConsents.push({
          state: 'accepted',
          name: serviceConsent.name,
          version: serviceConsent.version,
        });
        addedConsent = false;
      }
    });

    const phone = data?.phone_no_country_code ? `${data.phone_country_code}${data.phone_no_country_code}`.trim() : '';

    const mutationData = {
      customerToken: token ?? '',
      data: {
        first_name: String(data.first_name).trim(),
        last_name: String(data.last_name).trim(),
        password: String(data.password).trim(),
        phone,
        consents: mappedFormDataToAvailableConsents ?? [],
      },
    };
    mutation.mutate(mutationData);
  });

  return (
    <>
      <div>
        <StepNavigation labelArray={signupNavigationSteps} currentStep={currentStep} />
      </div>

      <h1 className="text-center lg:text-left">{t('web_validate_title')}</h1>
      <p className="text-left">{t('web_validate_description')}</p>

      {!showUnknownErrors && notification && (
        <div className="border border-green-500  b-slate-100 rounded-lg mt-8 mb-3 p-8" role="alert">
          <span dangerouslySetInnerHTML={{ __html: notification }}></span>
        </div>
      )}

      {showUnknownErrors && mutation.isError && <AxiosErrorComponent axiosError={mutation?.error} />}

      {showUnknownErrors && verifyCustomerError && !mutation.isError && (
        <AxiosErrorComponent axiosError={verifyCustomerError} />
      )}

      {showUnknownErrors && availableConsentsError && !mutation.isError && (
        <AxiosErrorComponent axiosError={availableConsentsError} />
      )}

      <form className="flex flex-col items-center py-8 space-y-2" onSubmit={onSubmit}>
        <EmailInput
          label={t('web_forms_email_label')}
          placeholder={t('web_forms_email_placeholder')}
          name="email"
          readonly
          isEmailVerified={!!getValues('email')}
          register={register}
          errors={errors}
          disabled={true}
        />

        <PasswordInput
          type="password"
          label={t('web_forms_password_label')}
          placeholder={t('web_forms_password_placeholder')}
          name="password"
          required={`${t('web_forms_password_label')} ${t('web_forms_isRequired')}`}
          currentPassword={getValues('password')}
          minLength={10}
          maxLength={60}
          register={register}
          errors={errors}
        />

        <div className="flex w-full space-x-4">
          <Input
            label={t('web_forms_name_label')}
            placeholder={t('web_forms_firstname_placeholder')}
            name="first_name"
            required={`${t('web_forms_firstname_placeholder')} ${t('web_forms_isRequired')}`}
            register={register}
            errors={errors}
          />

          <Input
            label="&nbsp;"
            placeholder={t('web_forms_lastname_placeholder')}
            name="last_name"
            required={`${t('web_forms_lastname_placeholder')} ${t('web_forms_isRequired')}`}
            register={register}
            errors={errors}
          />
        </div>

        <PhoneInput
          label={t('web_forms_phone_number_label')}
          type="text"
          name="phone_no_country_code"
          defaultValue=""
          cc={cc}
          // currentCountryCode={getValues('phone_country_code')}
          register={register}
          errors={errors}
        />

        <div className="w-full space-y-7 pb-4">
          {availableConsents.map((consent) => (
            <TermsCheckbox
              key={consent.name}
              label={t(`web_auth_consent_${consent.name?.split('_')?.[1] ?? 'terms'}_label`)}
              name={consent.name}
              register={register}
              currentValue={getValues(consent.name)}
              required={
                consent?.is_required === false
                  ? consent?.is_required_signup === true
                    ? t('web_forms_termsIsRequired')
                    : false
                  : t('web_forms_termsIsRequired')
              }
              errors={errors}
            />
          ))}
        </div>

        <Button
          className={'w-[12rem]'}
          loading={mutation.isPending}
          disabled={!isValid || mutation.isPending}
          type="submit"
        >
          {t('web_forms_createUser')} {mutation.isPending && '...'}
        </Button>

        <p className="pt-6 text-xs">
          <span className="" dangerouslySetInnerHTML={{ __html: t('web_auth_forms_gdpr') }} />
        </p>
      </form>
    </>
  );
};

export default Validate;
