import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { DEFAULT_LOCALES } from './appConfig';
const supportedLngs = DEFAULT_LOCALES.map((locale) => locale.id);
const VITE_TRANSLATION_API_URL = import.meta.env?.VITE_TRANSLATION_API_URL ?? 'https://translation.nordiskfilmplus.com';

import translationDA from './assets/locales/da/da_DK.json';
import translationEN from './assets/locales/en/en_GB.json';
import translationNB from './assets/locales/nb/nb_NO.json';
import translationFI from './assets/locales/fi/fi_FI.json';
import translationSV from './assets/locales/sv/sv_SE.json';

const resources = {
  da: { translation: translationDA },
  en: { translation: translationEN },
  nb: { translation: translationNB },
  fi: { translation: translationFI },
  sv: { translation: translationSV },
};

const i18n = use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources, // not needed when using chained backend
    fallbackLng: 'da',
    supportedLngs, // ['da', 'en', 'sv'],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
      lookupFromPathIndex: 1, // /dk/da/signup => dk = 0, da => 1
    },
    // debug: !import.meta.env.PROD,

    // Loading external resources
    // partialBundledLanguages: true,
    // ns: [],

    // backend: {
    //   // requestOptions: {
    //   //   mode: 'cors',
    //   // },
    //   crossDomain: true,
    //   loadPath: 'https://nordisk-film-translations.s3.eu-north-1.amazonaws.com/da_DK.json',
    // },

    backend: {
      backends: [HttpBackend, resourcesToBackend(resources)],
      backendOptions: [
        {
          // loadPath: 'https://nordisk-film-translations.s3.eu-north-1.amazonaws.com/da_DK.json',
          // loadPath: 'https://nordisk-film-translations.s3.eu-north-1.amazonaws.com/{{lng}}{{ns}}.json',
          crossDomain: true, // re-deploy
          loadPath: ([lgn] = 'da') => {
            const fileName = DEFAULT_LOCALES.find((f) => f.id === lgn)?.lngToFile ?? 'da_DK';
            return `${VITE_TRANSLATION_API_URL}/${fileName}.json`;
          },
        },
      ],
    },
  });

export default i18n;
