import { Helmet } from 'react-helmet';
import i18next from '../../i18n';
import './nordiskfilmplus_consent_banner.css';

function CookieConsent() {
  const langCode = i18next.language ? i18next.language.toUpperCase() : 'EN';

  return (
    <Helmet>
      <script
        defer
        id="CookieConsent"
        src="https://policy.app.cookieinformation.com/uc.js"
        data-culture={langCode}
        type="text/javascript"
        async
      ></script>
    </Helmet>
  );
}

export default CookieConsent;
