// TODO i18n

import { StepNavigationStep } from '@/components/progressBar/StepNavigation';

export const signupNavigationSteps: StepNavigationStep[] = [
  { label: 'Sign up' },
  { label: 'Verify email' },
  { label: 'Create account' },
  { label: 'Done' },
];
